@import "variables";

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;
}

.font-family-Montserrat {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;
}

.font-family-AGP-Bold {
  font-family: 'AGP Medium', sans-serif;
  font-style: italic;
  line-height: normal;
}

.text-xl {
  font-size: 2.05vw; // 66
}

.text-l {
  font-size: 1.85vw; // 60
}

.text-m {
  font-size: 1.35vw; // 42
}

.text-s {
  font-size: 1.1vw; // 34
}

.text-xs {
  font-size: 0.95vw; // 30
}

.text-next-season {
  font-size: 1.65vw;
}

.text-tooltip {
  font-size: 0.85vw; // 26
}

// 32 36 40 52 76 97
.text-xxl-mob {
  font-size: 7.1vw; // 97
}

.text-xl-mob {
  font-size: 5.6vw; // 76
}

.text-l-mob {
  font-size: 3.9vw; // 52
}

.text-m-mob {
  font-size: 3.1vw; // 40
}

.text-s-mob {
  font-size: 2.75vw; // 36
}

.text-xs-mob {
  font-size: 2.4vw; // 32
}

// 32 36 40 52 76 97

.font-w-900 {
  font-weight: 900;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-400 {
  font-weight: 400;
}

.white-color {
  color: $white;
}

.black-color {
  color: $black;
}

.dark-blue-color {
  color: $dark-blue;
}

.turquoise-color {
  color: $turquoise;
}

.orange-color {
  color: $orange;
}

.gray-color {
  color: $gray;
}

.yellow-color {
  color: $yellow;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.w-100 {
  width: 100%;
}

.pointer-events-none {
  pointer-events: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-default {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.margin-bottom-m {
  margin-bottom: 1vw;
}

.margin-bottom-s {
  margin-bottom: 0.4vw;
}

.margin-left-l {
  margin-left: 2vw;
}

.margin-left-m {
  margin-left: 1vw;
}

.margin-left-s {
  margin-left: 0.4vw;
}

.margin-right-m {
  margin-right: 0.8vw;
}

.margin-bottom-m-mob {
  margin-bottom: 2vw;
}

.margin-bottom-l-mob {
  margin-bottom: 4vw;
}

.margin-left-s-mob {
  margin-left: 1.2vw;
}

.margin-left-m-mob {
  margin-left: 2vw;
}

.margin-left-l-mob {
  margin-left: 4vw;
}

.margin-bottom-s-mob {
  margin-bottom: 1.2vw;
}

.margin-right-m-mob {
  margin-right: 2vw;
}

.margin-top-s {
  margin-top: 0.4vw;
}

.margin-top-m {
  margin-top: 1.2vw;
}

.margin-top-s-mob {
  margin-top: 1.2vw;
}

.margin-top-m-mob {
  margin-top: 2vw;
}

.margin-top-minus-s {
  margin-top: -0.4vw;
}