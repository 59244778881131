@keyframes pulsate {
    0%, 100% {
        color: #00EFA0;
        text-shadow: 0 -1px #030c21;
    }
    50% {
        color: #00EFA0;
        text-shadow: 0 -1px #030c21, 0 0 6px #00EFA0, 0 0 12px #00EFA0;
    }
}

.pulsate-text {
    color: #00EFA0;
    animation: pulsate 1.6s linear infinite;
}
